import React from 'react';
import { useNavigate } from 'react-router-dom';

function RegisterButton() {

    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/register');
    };

    return (
        <button type='button' className='btn navbar-btn' onClick={handleClick}>
            Register New Admin
        </button>
    );
}

export default RegisterButton;
