import React, { useState ,useEffect} from 'react'
import API from "../api/api";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import QRCode from 'qrcode.react';

export default function GenerateXml() {
  let isValid = true;
  const [formData, setFormData] = useState({
    title: 'JVoIP',
    username: '',
    password: '',
    host_address: '',
    voice_mailNumber: '*97',
    proxy: 'sbc.ucaasnet.com',
    transport: 'udp',
    allowMessage: '1'
  })

  const [formError, setFormError] = useState({
    title: '',
    username: '',
    password: '',
    host_address: '',
    voice_mailNumber: '',
    transport: '',
    validate: ''
  })

  const [isLoading, setIsLoading] = useState(false);
  const [xmlUrl, setXmlUrl] = useState('')
  const [copyStatus, setCopyStatus] = useState(false); // To indicate if the text was copied

  const onCopyText = () => {
    setCopyStatus(true);
    setTimeout(() => setCopyStatus(false), 2000); // Reset status after 2 seconds
  };

  const handleData = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
    setIsLoading(true);

    const errors = {
      title: '',
      username: '',
      password: '',
      host_address: '',
      voice_mailNumber: '',
      validate: ''
    };

    if (!formData.title.trim()) {
      errors.title = 'Title is required';
      isValid = false
    }

    if (!formData.username.trim()) {
      errors.username = 'Username is required';
      isValid = false
    }

    if (!formData.password.trim()) {
      errors.password = 'Password is required';
      isValid = false
    }

    if (!formData.host_address.trim()) {
      errors.host_address = 'Host Address is required';
      isValid = false
    }

    if (!formData.voice_mailNumber.trim()) {
      errors.voice_mailNumber = 'Voice Mail Number is required'
      isValid = false
    }

    if (!formData.transport.trim()) {
      errors.transport = 'Transport is required'
      isValid = false
    }

    console.log(isValid);
    if (!isValid) {
      errors.validate = 'Please fill all the required fields'
      setIsLoading(false);
    }

    setFormError(errors);

    if (isValid) {
      setFormError({
        ...formError,
        title: '',
        username: '',
        password: '',
        host_address: '',
        voice_mailNumber: '',
        transport: '',
        validate: ''
      });
      try {
        console.log(formData.title);
        API.post('/generate-xml', formData).then((res) => {
          setIsLoading(false);
          console.log(res);
          setXmlUrl(res?.data.url);
        }).catch((err) => {
          console.log(err);
          setFormError({
            ...formError,
            validate: (err?.response?.data?.message)
          })
        })
      } catch (error) {
        console.error(error);
      }
    }

  }

  const handelReset = (e) => {
    console.log('in handle reset ');
    setFormData({
      title: 'JVoIP',
      username: '',
      password: '',
      host_address: '',
      voice_mailNumber: '*97',
      proxy: 'sbc.ucaasnet.com',
      transport: 'udp',
      allowMessage: '1'
    })
    setXmlUrl('');
  }
  
   // State to hold QR code data
   const [qrCodeData, setQRCodeData] = useState('');

   useEffect(() => {
     // When xmlUrl changes, update QR code data
     if (xmlUrl) {
       setQRCodeData(xmlUrl);
     }
   }, [xmlUrl]);

  return (
    <form onSubmit={handleSubmit}>
      <h3>Generate XML</h3>
      {formError.validate && <div className="text-danger error-message my-3">{formError.validate}</div>}
      { !xmlUrl &&
      <>
      <div className="mb-4">
        <input
          type="text"
          name='title'
          className={`form-control ${formError.title ? 'error-highlight' : ''}`}
          placeholder="Account Title"
          value={formData.title}
          onChange={(e) => handleData(e)}
        />
      </div>

      <div className="mb-4">
        {/* <label>Last name</label> */}
        <input
          type="text"
          name='username'
          className={`form-control ${formError.username ? 'error-highlight' : ''}`}
          placeholder="Username"
          onChange={(e) => handleData(e)}
        />
      </div>

      <div className="mb-4">
        <input
          type="password"
          name='password'
          className={`form-control ${formError.password ? 'error-highlight' : ''}`}
          placeholder="Password"
          onChange={(e) => handleData(e)}
        />
      </div>

      <div className="mb-4">
        <input
          type="text"
          name='host_address'
          className={`form-control ${formError.host_address ? 'error-highlight' : ''}`}
          placeholder="Domain"
          onChange={(e) => handleData(e)}
        />
      </div>

      <div className="mb-4">
        <input
          type="text"
          name='voice_mailNumber'
          className={`form-control ${formError.voice_mailNumber ? 'error-highlight' : ''}`}
          placeholder="Voice Mail Number"
          onChange={(e) => handleData(e)}
          value={formData.voice_mailNumber}
        />
      </div>

      <div className="mb-4">
        <input
          type="text"
          name='proxy'
          className="form-control"
          placeholder="Proxy"
          onChange={(e) => handleData(e)}
          value={formData.proxy}
        />
      </div>

      <div className="mb-4">
        <Form.Select 
        value={formData.transport}           
        className={`form-control ${formError.transport ? 'error-highlight' : ''}`}
        onChange={(e) => handleData(e)}
        name='transport'
        >
          <option value="udp">udp</option>
          <option value="tcp">tcp</option>
          <option value="tls">tls</option>
        </Form.Select>
      </div>

      <div className="mb-4">
        <input
          type="text"
          name='allowMessage'
          className={`form-control ${formError.allowMessage ? 'error-highlight' : ''}`}
          placeholder="Allowed Number"
          onChange={(e) => handleData(e)}
          value={formData.allowMessage}
        />
      </div>
      

      <Container>
        <Row>
        <button type="submit" className="w-100 btn btn-primary">
              {isLoading ? (
                <>
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  &nbsp; Processing...
                </>
              ) : (
                "Generate XML"
              )}
            </button>
        </Row>
      </Container>
      </>
      }
      {xmlUrl &&
        <>
          <div className='row mt-4 text-center'>
            <span className='link-title'>Get your xml link: </span>
            <div className='col-md-12 mt-3'>
              <textarea
                className='w-100 xml-text-area'
                value={xmlUrl}
                placeholder="Type or paste text here..."
                disabled
              />
            </div>
            <div className='col-md-12 mt-3'>
              <CopyToClipboard text={xmlUrl}>
                <button type='button' onClick={onCopyText} className="w-100 btn btn-primary">Copy To Clipboard</button>
              </CopyToClipboard>
            </div>
            {copyStatus && <span className="text-success m-2">Copied!</span>}
             {/* QR Code section */}
                {qrCodeData && (
                  <div className='mt-2 text-center'>
                    <div className='mt-3'>
                      {/* Render QR code */}
                      <QRCode value={qrCodeData} />
                    </div>
                  </div>
                )}
            <button type="reset" className="w-100 btn btn-primary mt-3" onClick={(e) => handelReset(e)}>
              Reset
            </button>
          </div>
        </>

      }

    </form>
  )
}