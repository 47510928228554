import './App.css';
import { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Link, Navigate } from 'react-router-dom'
import GenerateXml from './components/generateXml';
import 'bootstrap/dist/css/bootstrap.min.css';
import ProtectedRoute from './auth/protected-route';
import Login from './components/login';
import LogoutButton from './components/logout-btn';
import Register from './components/register-admin';
import RegisterButton from './components/register-btn';
import ChangePassword from './components/change-password';
import ChangePasswordButton from './components/change-password-btn';



function App() {

  const [token, setToken] = useState(localStorage.getItem('token'));

  return (
    <Router>
      <div className="App">
        <nav className="navbar navbar-expand-lg navbar-light bg-light fixed-top">
          <div className="container">
            <Link to="/" className="navbar-brand">JVOIP XML GENERATOR</Link>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
              <ul className="navbar-nav ms-auto">
                {token && 
                 <>
                 <li className="nav-item mx-1"><RegisterButton /></li>
                 <li className="nav-item mx-1"><ChangePasswordButton /></li>
                 <li className="nav-item mx-1"><LogoutButton setToken={setToken} /></li>
                 </>
                
                }
              </ul>
            </div>
          </div>
        </nav>
        <div className="auth-wrapper p-4">
          <div className="auth-inner">
            <Routes>
              <Route path="/sign-in" element={token ? <Navigate to="/" /> : <Login setToken={setToken} />} />
              <Route path="/register" element={<ProtectedRoute component={Register} />} />
              <Route path="/change-password" element={<ProtectedRoute component={ChangePassword} />} />
              <Route path="/" element={<ProtectedRoute component={GenerateXml} />} />
            </Routes>
          </div>
        </div>
      </div>
    </Router>
  );
}

export default App;
