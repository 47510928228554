import React from 'react';
import { useNavigate } from 'react-router-dom';

function LogoutButton(props) {

    const { setToken } = props;
    const navigate = useNavigate();

    const handleLogout = () => {
        localStorage.removeItem('token');
        setToken(null);
        navigate('/sign-in');
    };

    return (
        <button type='button' className='btn navbar-btn' onClick={handleLogout}>
            Logout
        </button>
    );
}

export default LogoutButton;
