import axios from 'axios';

const API = axios.create({
    baseURL: "https://jvoipxgen.jvoip.io/server/voip-backend/api",
});
API.interceptors.request.use(
    (request) => {
        const auth = "Bearer " + localStorage.getItem("token");
        request.headers["Accept"] = "application/json";
        request.headers["Authorization"] = auth;
        return request;
    },
    (error) => {
        return Promise.reject(error);
    }
);
export default API;


//http://localhost:5000/api
//https://jvoipxgen.jvoip.io/server/voip-backend/api
