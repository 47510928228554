import React from 'react';
import { useNavigate } from 'react-router-dom';

function ChangePassword() {

    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/change-password');
    };

    return (
        <button type='button' className='btn navbar-btn' onClick={handleClick}>
            Change Password
        </button>
    );
}

export default ChangePassword;
