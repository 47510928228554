/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import API from "../api/api";
import { useNavigate } from 'react-router-dom';

export default function Login(props) {

  const navigate = useNavigate();

  let isValid = true;

  const [adminSuccess, setAdminSuccess] = useState('');
  const [redirectToHome, setRedirectToHome] = useState('');
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    confirm_password: '',
  })

  const handleData = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    })
  }

  const [formError, setFormError] = useState({
    email: '',
    password: '',
    confirm_password: '',
    validate: '',
    passwordRequiremnt: ''
  })

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);

    const errors = {
      email: '',
      password: '',
      confirm_password: '',
      validate: '',
      passwordRequiremnt: ''
    };

    if (!formData.email.trim()) {
      errors.email = 'Email is required';
      isValid = false
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const passwordStrength = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^a-zA-Z\d\s])[a-zA-Z\d\W]{10,}$/;

    console.log('passwordStrength ==',passwordStrength.test(formData.password));
    if (formData?.email.trim() && !emailRegex.test(formData.email)) {
      errors.email = 'Invalid email';
      isValid = false;
    }

    if (!formData?.password.trim()) {
      errors.password = 'Password is required';
      isValid = false
    }

    if(!passwordStrength.test(formData.password) && isValid){
      errors.passwordRequiremnt = 'Password must contain at least 10 characters, one uppercase, lowercase, number and one symbol';
      isValid = false
    }

    if (formData?.password !== formData?.confirm_password && isValid) {
      errors.confirm_password = 'Passwords do not match';
      isValid = false
    }   

    setFormError(errors);


    if (isValid) {
      console.log(formData);
      setFormError({
        email: '',
        password: '',
        confirm_password: '',
      })
      API.post('/create-admin', formData).then((res) => {
        console.log('res ==',res);
        //props.setToken(res.data.user.token);
        setAdminSuccess(res?.data?.message);
        setRedirectToHome('Redirecting to Home...')
        setTimeout(() => {
          setAdminSuccess('');
          navigate('/');
        }, 5000);
        
      }).catch((err) => {
        console.log('err in resp == ',err);
        setFormError({
          ...formError,
          validate: (err?.response?.data?.message) ?? "Something went Wrong."
        })
      })
    }
  }

  return (
    <form onSubmit={handleSubmit}>
        
      <h3>Register New Admin</h3>
      {formError.validate && <div className="text-danger error-message my-3">{formError.validate}</div>}

      <div className="mb-3">
        {/* <label>Email address</label> */}
        <input
          type="email"
          name="email"
          className="form-control"
          placeholder="Enter email"
          onChange={(e) => handleData(e)}
        />
      </div>
      {formError.email && <div className="text-danger error-message my-3">{formError.email}</div>}

      <div className="mb-3">
        {/* <label>Password</label> */}
        <input
          type="password"
          name="password"
          className="form-control"
          placeholder="Enter password"
          onChange={(e) => handleData(e)}
        />
      </div>
      {formError.password && <div className="text-danger error-message my-3">{formError.password}</div>}
      <div className="mb-3">
        {/* <label>Password</label> */}
        <input
          type="password"
          name="confirm_password"
          className="form-control"
          placeholder="Confirm Password"
          onChange={(e) => handleData(e)}
        />
      </div>
      {formError.confirm_password && <div className="text-danger error-message my-3">{formError.confirm_password}</div>}
      {formError.passwordRequiremnt && <div className="text-danger my-3">{formError.passwordRequiremnt}</div>}


      {/* <div className="mb-3">
        <div className="custom-control custom-checkbox">
          <input
            type="checkbox"
            className="custom-control-input"
            id="customCheck1"
          />
          <label className="custom-control-label" htmlFor="customCheck1">Remember me</label>
        </div>
      </div> */}
      <div className="d-grid">
        <button type="submit" className="btn btn-primary">Submit</button>
      </div>

      {adminSuccess && <div className="text-success success-message my-3">{adminSuccess}</div>}
      {redirectToHome && <div className="text-black mt-2">{redirectToHome}</div>}
      {/* <p className="forgot-password text-right">
                Forgot <a href="#">password?</a>
            </p> */}
    </form>
  );
}

