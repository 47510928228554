/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import API from "../api/api";
import { useNavigate } from 'react-router-dom';

export default function ChangePassword(props) {

  const navigate = useNavigate();

  let isValid = true;

  const [passwordSuccess, setPasswordSuccess] = useState('')
  const [redirectToHome, setRedirectToHome] = useState('');
  const [formData, setFormData] = useState({
    old_password: '',
    new_password: '',
    confirm_password: '',
  })

  const handleData = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    })
  }

  const [formError, setFormError] = useState({
    old_password: '',
    new_password: '',
    confirm_password: '',
    passwordRequiremnt: '',
    validate: ''
  })

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);

    const errors = {
      old_password: '',
      new_password: '',
      confirm_password: '',
      passwordRequiremnt: '',
      validate: ''
    };

    const passwordStrength = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^a-zA-Z\d\s])[a-zA-Z\d\W]{10,}$/;
    if (!formData.old_password.trim()) {
      errors.old_password = 'Old Password is required';
      isValid = false
    }

    if (!formData?.new_password.trim()) {
      errors.new_password = 'Password is required';
      isValid = false
    }

    if (formData?.new_password !== formData?.confirm_password) {
      errors.confirm_password = 'Passwords do not match';
      isValid = false
    }   

    if(!passwordStrength.test(formData.new_password)) {
      errors.passwordRequiremnt = 'Password must contain at least 10 characters, one uppercase, lowercase, number and one symbol';
      isValid = false;
    }
    setFormError(errors);


    if (isValid) {
      console.log(formData);
      setFormError({
        old_password: '',
        new_password: '',
        confirm_password: '',
      })
      API.post('/change-password', formData).then((res) => {
        console.log('res ==',res);
        //props.setToken(res.data.user.token);
        setPasswordSuccess(res?.data?.message);
        setRedirectToHome('Redirecting to Home...')
        setTimeout(() => {
          setPasswordSuccess('');
          navigate('/');
        }, 5000);
        
      }).catch((err) => {
        console.log('err in resp == ',err);
        setFormError({
          ...formError,
          validate: (err?.response?.data?.message) ?? "Something went Wrong."
        })
      })
    }
  }

  return (
    <form onSubmit={handleSubmit}>
        
      <h3>Change Password</h3>
      {formError.validate && <div className="text-danger error-message my-3">{formError.validate}</div>}

      <div className="mb-3">
        {/* <label>Email address</label> */}
        <input
          type="text"
          name="old_password"
          className="form-control"
          placeholder="Enter Old Password"
          onChange={(e) => handleData(e)}
        />
      </div>
      {formError.old_password && <div className="text-danger error-message my-3">{formError.old_password}</div>}

      <div className="mb-3">
        {/* <label>Password</label> */}
        <input
          type="text"
          name="new_password"
          className="form-control"
          placeholder="Enter New password"
          onChange={(e) => handleData(e)}
        />
      </div>
      {formError.new_password && <div className="text-danger error-message my-3">{formError.new_password}</div>}

      <div className="mb-3">
        {/* <label>Password</label> */}
        <input
          type="text"
          name="confirm_password"
          className="form-control"
          placeholder="Confirm New                                                                                                                                                                                                                                                                                                                                                                                                                                                                                Password"
          onChange={(e) => handleData(e)}
        />
      </div>
      {formError.confirm_password && <div className="text-danger error-message my-3">{formError.confirm_password}</div>}
      {formError.passwordRequiremnt && <div className="text-danger my-3">{formError.passwordRequiremnt}</div>}

      {/* <div className="mb-3">
        <div className="custom-control custom-checkbox">
          <input
            type="checkbox"
            className="custom-control-input"
            id="customCheck1"
          />
          <label className="custom-control-label" htmlFor="customCheck1">Remember me</label>
        </div>
      </div> */}
      <div className="d-grid">
        <button type="submit" className="btn btn-primary">Submit</button>
      </div>

      {passwordSuccess && <div className="text-success success-message my-3">{passwordSuccess}</div>}
      {redirectToHome && <div className="text-black mt-2">{redirectToHome}</div>}
      {/* <p className="forgot-password text-right">
                Forgot <a href="#">password?</a>
            </p> */}
    </form>
  );
}

