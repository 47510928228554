/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState,useRef } from "react";
import API from "../api/api";
import { useNavigate } from 'react-router-dom';
import ReCAPTCHA from "react-google-recaptcha"

export default function Login(props) {

  const navigate = useNavigate();
  const captchaRef = useRef(null)

  let isValid = true;
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  })

  const handleData = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    })
  }

  const [formError, setFormError] = useState({
    email: '',
    password: '',
    validate: ''
  })

  const handleSubmit = async (e) => {
    const retoken = captchaRef.current.getValue();
    captchaRef.current.reset();
    const inputVal = await e.target[0].value;


    e.preventDefault();
    
    const errors = {
      email: '',
      password: '',
      validate: ''
    };

    if (!formData.email.trim()) {
      errors.email = 'Email is required';
      isValid = false
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (formData?.email.trim() && !emailRegex.test(formData.email)) {
      errors.email = 'Invalid email';
      isValid = false;
    }

    if (!formData?.password.trim()) {
      errors.password = 'Password is required';
      isValid = false
    }

    setFormError(errors);


    if (isValid) {
      console.log(formData);
      API.post('/login', {...formData,inputVal,retoken}).then((res) => {
        localStorage.setItem('token', res.data.user.token);
        props.setToken(res.data.user.token);
        navigate('/');
      }).catch((err) => {
        console.log(err);
        setFormError({
          ...formError,
          validate: (err?.response?.data?.message) ?? "Email or password is incorrect" 
        })
      })
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <h3>LOG IN</h3>
      {formError.validate && <div className="text-danger error-message my-3">{formError.validate}</div>}

      <div className="mb-3">
        {/* <label>Email address</label> */}
        <input
          type="email"
          name="email"
          className="form-control"
          placeholder="Enter email"
          onChange={(e) => handleData(e)}
        />
      </div>
      {formError.email && <div className="text-danger error-message my-3">{formError.email}</div>}

      <div className="mb-3">
        {/* <label>Password</label> */}
        <input
          type="password"
          name="password"
          className="form-control"
          placeholder="Enter password"
          onChange={(e) => handleData(e)}
        />
      </div>
      {formError.password && <div className="text-danger error-message my-3">{formError.password}</div>}

      {/* <div className="mb-3">
        <div className="custom-control custom-checkbox">
          <input
            type="checkbox"
            className="custom-control-input"
            id="customCheck1"
          />
          <label className="custom-control-label" htmlFor="customCheck1">Remember me</label>
        </div>
      </div> */}
      <div className="d-grid">
        <ReCAPTCHA sitekey={process.env.REACT_APP_SITE_KEY} ref={captchaRef}/>
        <button type="submit" className="btn btn-primary mt-2">Submit</button>
      </div>
      {/* <p className="forgot-password text-right">
                Forgot <a href="#">password?</a>
            </p> */}
    </form>
  );
}

